"use client";
import Link from "next/link";
import {
  UnkeyFooterLogo,
  UnkeyFooterLogoMobile,
  UnkeyLogoSmall,
  UnkeyLogoSmallMobile,
} from "./footer-svgs";
import { socialMediaProfiles } from "./social-media";
import { useSelectedLayoutSegment } from "next/navigation";

import Image from "next/image";

import qrImage from '../../components/images/WechatQR.jpg'

const navigation = [
  // {
  //   title: "Company",
  //   links: [
  //     { title: "About", href: "/about" },
  //     { title: "Blog", href: "/blog" },
  //     { title: "Changelog", href: "/changelog" },
  //     { title: "Templates", href: "/templates" },
  //     {
  //       title: "Analytics",
  //       href: "https://us.posthog.com/shared/HwZNjaKOLtgtpj6djuSo3fgOqrQm0Q?whitelabel",
  //     },
  //     {
  //       title: "Source Code",
  //       href: "https://github.com/unkeyed/unkey",
  //     },
  //     {
  //       title: "Docs",
  //       href: "https://unkey.dev/docs",
  //     },
  //   ],
  // },
  // {
  //   title: "Connect",
  //   links: socialMediaProfiles,
  // },
  {
    title: "",
    links: [
      { title: "Twitter", href: "https://twitter.com/JellyDropsLLC" },
      // { title: "iSDK Twitter", href: "https://twitter.com/JellyDropsLLC" },
      { title: "GitHub", href: "https://github.com/JellyDropsLLC" },
    ],
  },
];

function CompanyInfo() {
  return (
    <div className="flex flex-col ">
      {/* <UnkeyLogoSmall /> */}
      <div className="font-normal text-sm leading-6  mt-8">
        深圳果冻移民咨询
      </div>
      <div className="font-normal text-sm leading-6 ">
        更多解释权归果冻移民所有
      </div>
    </div>
  );
}

function CompanyInfoMobile() {
  return (
    <div className="flex flex-col items-center ">
      {/* <UnkeyLogoSmallMobile /> */}
      <div className="font-normal text-sm leading-6  mt-10">
        深圳果冻移民咨询
      </div>
      <div className="font-normal text-sm leading-6 ">
        更多解释权归果冻移民所有
      </div>
      <div className="mt-10">
        {/* {navigation.map((section, index) => (
          <div key={index}>
            <h3 className="py-4 text-sm font-medium ">{section.title}</h3>
            <ul className="text-sm text-[rgba(255,255,255,0.7)] font-normal">
              {section.links.map((link) => (
                <li key={link.href.toString()} className="py-1">
                  {link.href.startsWith("https://") ? (
                    <a
                      href={link.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="transition hover:text-[rgba(255,255,255,0.4)]"
                    >
                      {link.title}
                    </a>
                  ) : (
                    <Link
                      href={link.href}
                      className="transition hover:text-[rgba(255,255,255,0.4)]"
                    >
                      {link.title}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))} */}

        <figure
          className='w-full'
        ><Image src={qrImage} className='w-full' alt="QR Image" /></figure>
      </div>
      {/* <div className="flex justify-center w-full lg:mt-24">
        <UnkeyFooterLogo />
      </div> */}
    </div>
  );
}

function Navigation() {
  return (
    <nav className=" sm:w-full mb-20 text-[#000]">
      <ul className="flex flex-col flex-auto gap-16 text-left sm:flex-row sm:mx-auto sm:text-center justify-start mb-[3rem]">
        {navigation.map((section) => (
          <li key={section.title.toString()}>
            <div className="text-sm font-medium tracking-wider font-display">
              {section.title}
            </div>
            <ul className="text-sm text-[rgba(255,255,255,0.7)] font-normal gap-2 flex  flex-row md:gap-8 mt-4 md:mt-8 ">
              {/* {section.links.map((link) => (
                <li key={link.href.toString()}>
                  {link.href.startsWith("https://") ? (
                    <a
                      href={link.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="transition hover:text-[rgba(255,255,255,0.4)] text-[#000]"
                    >
                      {link.title}
                    </a>
                  ) : (
                    <Link
                      href={link.href}
                      className="transition  hover:text-[rgba(255,255,255,0.4)] text-[#000]"
                    >
                      {link.title}
                    </Link>
                  )}
                </li>
              ))} */}


            </ul>
          </li>
        ))}
        <figure
          className='w-[10rem] text-center'
        ><Image src={qrImage} className='w-full' alt="QR Image" /></figure>
      </ul>
      {/* <Link
        href="/#index-target"
        className="transition text-[#fff] hover:text-[rgba(255,255,255,0.4)] text-[#000]"
      >
        Back to top
      </Link> */}


    </nav>
  );
}

function MobileNavigation() {
  return (
    <nav className="flex flex-col sm:hidden">
      <div className="flex flex-col items-center justify-center w-full text-center mb-[2rem]">
        <CompanyInfoMobile />
      </div>
      {/* <div className="flex justify-center w-full lg:mt-24">
        <UnkeyFooterLogoMobile />
      </div> */}
    </nav>
  );
}

export function Footer() {
  const segment = useSelectedLayoutSegment();

  let isOK = true
  if (segment && ["auth", "/auth/login/", "/auth/signup/", "new", "manage"].includes(segment)) {
    isOK = false
  }

  if (!isOK) {
    return (<></>)
  }
  return (
    <>
      <footer className="relative hidden pt-32 overflow-hidden border-none sm:block xl:pt-10 max-sm:pt-8 border-white/10 blog-footer-radial-gradient bg-[#F0F0F0] text-[#000]">
        <div className="container flex flex-col mx-auto">
          <div className="flex flex-row justify-center max-sm:flex-col sm:flex-col md:flex-row xl:gap-20 xxl:gap-48">
            <div className="flex mb-8 lg:mx-auto max-sm:pl-12 max-sm:flex sm:flex-row sm:w-full sm:pl-28 xl:pl-14 md:w-5/12 shrink-0 xxl:pl-28">
              <CompanyInfo />
            </div>
            <div className="flex w-full max-sm:pl-12 max-sm:pt-6 max-sm:mt-22 sm:pl-28 md:pl-18 lg:pl-6 max-sm:mb-8">
              <Navigation />
            </div>
          </div>
        </div>
        {/* <div className="flex justify-center w-full">
          <UnkeyFooterLogo className="mt-4" />
        </div> */}
      </footer>
      <MobileNavigation />
    </>
  );
}
