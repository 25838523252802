import { Resource, createInstance, i18n } from 'i18next';
import { initReactI18next } from 'react-i18next/initReactI18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import LanguageDetector from "i18next-browser-languagedetector";
import i18nConfig from '@/i18nConfig';

export default async function initTranslations(
  locale: string,
  namespaces: string[],
  i18nInstance?: i18n,
  resources?: Resource
) {
  i18nInstance = i18nInstance || createInstance();

  i18nInstance
    // .use(LanguageDetector)
    .use(initReactI18next);

  if (!resources) {
    i18nInstance.use(
      resourcesToBackend(
        (language: string, namespace: string) =>
          import(`../locales/${language}/${namespace}.json`)
      )
    );
  }

  await i18nInstance.init({
    lng: locale,
    resources,
    fallbackLng: i18nConfig.defaultLocale,
    supportedLngs: i18nConfig.locales,
    // debug: false,
    interpolation: {
      escapeValue: false,
    },
    defaultNS: [...namespaces, "common"],
    fallbackNS: [...namespaces, "common"],
    ns: namespaces,
    preload: resources ? [] : i18nConfig.locales,
    // detection: {
    //   caches: ['localStorage', 'sessionStorage', 'cookie'],
    // },
  });

  //  // assume all detected   languages are available
  //  const detectLanguage = i18n.language;
  //  // cannot trust browser language setting
  // const settingLanguage = localStorage.getItem("language");

  // console.log(settingLanguage)

  //  // if setting is not initialized, but detected language is available, use detected language and update language setting
  //  if (
  //    !settingLanguage &&
  //    AvailableLanguages.some((lang) => detectLanguage === lang.value)
  //  ) {
  //    localStorage.setItem("language", detectLanguage);
  //    i18n.changeLanguage(detectLanguage);
  //    return;
  //  }

  //  // if setting is not initialized and detected language is not available, use en and update language setting
  //  if (
  //    !settingLanguage &&
  //    !AvailableLanguages.some((lang) => detectLanguage === lang.value)
  //  ) {
  //    localStorage.setItem("language", "en");
  //    i18n.changeLanguage("en");
  //    return;
  //  }

  //  // if setting is initialized and setting language is not available, use en and update language setting
  //  if (
  //    settingLanguage &&
  //    !AvailableLanguages.some((lang) => settingLanguage === lang.value)
  //  ) {
  //    localStorage.setItem("language", "en");
  //    i18n.changeLanguage("en");
  //    return;
  //  }

  //  // if setting is initialized and setting language is available, use setting language
  //  if (settingLanguage && settingLanguage !== detectLanguage) {
  //    i18n.changeLanguage(settingLanguage);
  //  }

  return {
    i18n: i18nInstance,
    resources: i18nInstance.services.resourceStore.data,
    t: i18nInstance.t
  };
}
